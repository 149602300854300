import {
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
  FormGroup
} from "@mui/material"
import {
  DescriptionList,
  FormHelperText,
  Heading3,
  InputField,
  LabeledField
} from "../../../../components/FormCommons"
import { Controller, useFormContext } from "react-hook-form"
import * as Yup from "yup"
import { getDietOptions } from "wakacje-web/src/utils/categories"
import { ProductUtils } from "wakacje-web/src/utils/ProductUtils"
import { FormSectionContainer } from "./components/FormSectionContainer"
import { getLocalizedKey } from "../../../../utils/getLocalizedKey"
import { useTranslation } from "react-i18next"

export const productSectionFoodFormSchema = {
  food_type: Yup.string().required("Wyżywienie jest wymagana")
}

export const ProductSectionFoodForm = ({ register, control, watch }: any) => {
  const { t } = useTranslation()
  const watchFoodType = watch("food_type")
  register("food_diets")
  const foodDietsValue = watch("food_diets")
  const { setValue } = useFormContext()

  const foodDescriptionKey = getLocalizedKey("food_description")

  return (
    <FormSectionContainer>
      <Heading3 id="h3-1" className="mb-4">
        {t("ui.groups.food", "Wyżywienie")}
      </Heading3>
      <div className="mb-4">
        <FormHelperText>
          {t(
            "ui.groups.food_question",
            "Czy w ramach wyjazdu przewiduje się wyżywienie dla uczestników?*"
          )}
        </FormHelperText>
      </div>

      <Controller
        name="food_type"
        control={control}
        render={({
          field,
          fieldState: { invalid, isTouched, isDirty, error },
          formState
        }) => (
          <RadioGroup {...field}>
            <div className="flex flex-row">
              <FormControlLabel
                label={t("ui.groups.yes", "Tak")}
                value="tak"
                control={<Radio value="tak" />}
              />
              <FormControlLabel
                label={t("ui.groups.no", "Nie")}
                value="nie"
                control={<Radio value="nie" />}
              />
            </div>
          </RadioGroup>
        )}
      />

      {/* <InputCheck
              control={control}
              label="Dieta Wegetariańska"
              name="food_vegetarian"
            /> */}

      {watchFoodType === "tak" && (
        <>
          <InputField
            label={t("ui.groups.description_header", "Opis")}
            multiline
            minRows={3}
            value={watch(foodDescriptionKey)}
            {...register(foodDescriptionKey)}
          />

          <p className="font-bold my-2">
            {t("ui.groups.specify_diet", "Możliwość dostosowania diety")}
          </p>
          <div className="mb-4 mt-2 text-sm text-fc-color-accent-4 ">
            {t(
              "ui.groups.specify_diet_description",
              "Wybierz opcjonalnie rodzaj diety, jaka może być dostosowana pod uczestników"
            )}
          </div>

          <Controller
            name="rejection_reason"
            control={control}
            render={({
              field,
              fieldState: { invalid, isTouched, isDirty, error },
              formState
            }) => (
              <div>
                {getDietOptions(t).map((op: any) => (
                  <div>
                    <FormGroup className="mb-4">
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={(event) => {
                              var filtered = foodDietsValue
                                ? foodDietsValue.filter((fs) => fs !== op.value)
                                : []

                              if (event.target.checked) {
                                filtered.push(op.value)
                              }

                              setValue("food_diets", filtered)
                            }}
                            checked={
                              foodDietsValue &&
                              foodDietsValue.indexOf(op.value) > -1
                            }
                          />
                        }
                        label={op.name}
                      />
                    </FormGroup>
                  </div>
                ))}
              </div>
            )}
          />
        </>
      )}
    </FormSectionContainer>
  )
}

export const ProductSectionFoodPreview = ({ values }: any) => {
  const { t } = useTranslation()
  const foodDescriptionKey = getLocalizedKey("food_description")

  var data: any = {
    [t("ui.groups.food", "Wyżywienie")]:
      values.food_type === "tak"
        ? t("ui.groups.yes", "Tak")
        : t("ui.groups.no", "Nie"),
    [t("ui.groups.food_description", "Wyżywienie - dodatkowe informacje")]:
      values[foodDescriptionKey] ?? "-"
  }
  return (
    <DescriptionList data={data}>
      {values.food_diets && values.food_diets.length > 0 && (
        <LabeledField label={t("ui.groups.diet", "Diety")}>
          {values.food_diets
            .map((d) => ProductUtils.getDietName(d, t))
            .join(", ")}
        </LabeledField>
      )}
    </DescriptionList>
  )
}

//Remove this because it is moved to Section Schedule
// export const ProductSectionFoodView = ({ values, refetch, watch }: any) => {
//   const { updateProduct } = useProductApi()

//   const saveHandler = async (data: any) => {
//     console.log("Save handler", data)
//     var productData: any = {
//       id: values.id,
//       food_type: data.food_type,
//       // food_vegetarian: data.food_vegetarian,  DEPR - move to food_diets
//       // food_vegan: data.food_vegan,DEPR - move to food_diets
//       food_description: data.food_description,
//       food_diets: data.food_diets
//     }
//     console.log("new product data", data)
//     return updateProduct(productData)
//   }
//   const handleRefresh = () => {
//     refetch()
//   }
//   return (
//     <EditableFormSection
//       title="Wyżywienie"
//       values={values}
//       onSave={saveHandler}
//       onSaved={handleRefresh}
//     >
//       {(builderProps: any) => {
//         console.log("Builder props", builderProps)
//         return (
//           <div>
//             {builderProps.isEditing && (
//               <ProductSectionFoodForm
//                 values={values}
//                 watch={watch}
//                 {...builderProps}
//               />
//             )}
//             {!builderProps.isEditing && (
//               <ProductSectionFoodPreview values={values} />
//             )}
//           </div>
//         )
//       }}
//     </EditableFormSection>
//   )
// }
