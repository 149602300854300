import {
  DescriptionList,
  InputField,
} from "../../../components/FormCommons"
import { useIntl } from "react-intl"
import { useCompanyApi } from "../events/api/useCompanyApi"
import { EditableFormSection } from "../../../components/forms/EditableFormSection"
import { Box } from "@mui/system"
import { Checkbox } from "@mui/material"
import { FormControlLabel } from "@mui/material"
import { useEffect, useState } from "react"
import { Controller } from "react-hook-form"

export const CompanySectionSuperAdminForm = ({
  register,
  control,
  watch,
}: any) => {
  const admin_approved_at = watch("admin_approved_at")
  console.log("admin_approved_at", admin_approved_at)
  return (
    <Box>
      <InputField
        {...register("fc_website_prefix")}
        label="Adres strony FC (fajnyczas.pl/nazwa-firmy)"
        name="fc_website_prefix"
        id={`fc_website_prefix`}
        InputLabelProps={{ shrink: true }}
      />
      <InputField
        {...register("license_type")}
        label="Pakiet"
        name="license_type"
        id={`license_type`}
        InputLabelProps={{ shrink: true }}
      />
      <InputField
        {...register("license_valid_till")}
        label="Licencja ważna do"
        name="license_valid_till"
        id={`license_valid_till`}
        InputLabelProps={{ shrink: true }}
      />
      <div className="font-semibold mt-2">Typ firmy</div>
      <Controller
        name="company_type"
        control={control}
        render={({ field }) => (
          <FormControlLabel
            label="Firma transportowa"
            labelPlacement="end"
            control={<Checkbox
            onChange={(e) => field.onChange(e.target.checked)}
            checked={field.value ?? false} />}
          />
        )}
      />
      <InputField
        {...register("administrator")}
        InputLabelProps={{ shrink: true }}
        label="Opiekun"
        id={`administrator`}
      />
      <InputField
        {...register("admin_comment")}
        InputLabelProps={{ shrink: true }}
        label="Uwagi Admin"
        id={`admin_comment`}
        multiline
      />
    </Box>
  )
}

export const CompanySectionSuperAdminPreview = ({ values }: any) => {
  const intl = useIntl()
  return (
    <div>
      <DescriptionList
        data={{
          "Adres strony FC (fajnyczas.pl/nazwa-firmy)": values.fc_website_prefix,
          "Pakiet": values.license_type,
          "Licencja ważna do": values.license_valid_till,
          /* todo: backend for company_type and administrator */
          "Typ firmy": values.company_type ? "Firma transportowa" : "",
          "Opiekun": values.administrator ?? "",
          "Uwagi Admin": values.admin_comment,
          //   "Pokaż na stronie głównej": values.admin_show_on_home_page
          //     ? "Tak"
          //     : "Nie",
          //   "Promuj na stronie głównej": values.admin_promote_on_home_page
          //     ? "Tak"
          //     : "Nie",
        }}
      />
    </div>
  )
}

export const CompanySectionSuperAdminView = ({ values, refetch }: any) => {

  const [val, setValue] = useState<any>(values)
  const { runCommand } = useCompanyApi()

  const update = (data: object) => {
    setValue((prevData) => ({ ...prevData, ...data }))
  }

  useEffect(() => {
    if (val.id !== values.id) {
      setValue(values)
    }
  }, [values])

  const saveHandler = async (data: any) => {
    console.log("Save handler", data)
    var command: any = {
      type: "Company.AdminUpdate",
      aggregateId: values.id,
      payload: {
        admin_comment: data.admin_comment,
        fc_website_prefix: data.fc_website_prefix,
        license_type: data.license_type,
        license_valid_till: data.license_valid_till,
        company_type: data.company_type,
        administrator: data.administrator

      },
    }
    console.log("new Company data", data)
    return runCommand(command)
  }
  const handleRefresh = () => {
    console.log("Call refetch")
    refetch()
  }
  //   const val

  return (
    <EditableFormSection
      title="Opcje administratora (Firma)"
      values={val}
      onSave={saveHandler}
      onSaved={handleRefresh}
      styles={"shadow rounded-lg"}
      onUpdate={update}
    >
      {(builderProps: any) => {
        console.log("Builder props", builderProps)
        return (
          <div>
            {builderProps.isEditing && (
              <CompanySectionSuperAdminForm values={val} {...builderProps} />
            )}
            {!builderProps.isEditing && (
              <CompanySectionSuperAdminPreview values={val} />
            )}
          </div>
        )
      }}
    </EditableFormSection>
  )
}
