import axios from "axios"
import useAxios from "axios-hooks"
import { useCompanyContext } from "../../../../contexts/company-context"

export const useProductApi = () => {
  const companyContext = useCompanyContext()
  const createProduct = async (data: any) => {
    var url =
      process.env.REACT_APP_WAKACJE_API_URL + "/products-admin/v1.1/products"
    var res = await axios.post(url, data)
    return res.data
  }

  const createTrip = async (productId: string, data: any) => {
    var url =
      process.env.REACT_APP_WAKACJE_API_URL +
      `/products-admin/v1.1/products/${productId}/trips/${data.id}`

    var res = await axios.post(url, data)
    return res.data
  }
  const updateProduct = async (data: any) => {
    if (!data.id) {
      throw new Error("No product.id")
    }
    var url =
      process.env.REACT_APP_WAKACJE_API_URL +
      `/products-admin/v1.1/products/${data.id}`

    var res = await axios.post(url, data)
    return res.data
  }

  const runProductCommand = async (command: any) => {
    if (!command.aggregateId) {
      throw new Error("No command.aggregateId")
    }
    var url =
      process.env.REACT_APP_WAKACJE_API_URL +
      `/products-admin/v1.1/products/${command.aggregateId}`

    var res = await axios.post(url, { command })
    return res.data
  }

  const runTripCommand = async (command: any) => {
    if (!command.aggregateId) {
      throw new Error("No command.aggregateId")
    }
    var url =
      process.env.REACT_APP_WAKACJE_API_URL +
      `/products-admin/v1.1/trips/${command.aggregateId}`

    var res = await axios.post(url, { command })
    return res.data
  }

  const listProducts = async (query: any = {}, pagination: any) => {
    query.company_id = companyContext.company?.id
    let searchParams = new URLSearchParams(query)
    var url =
      process.env.REACT_APP_WAKACJE_API_URL +
      `/products-admin/v1.1/products?${searchParams}`

    var res = await axios.get(url)
    return res.data
  }

  const superListProducts = async (query: any = {}, pagination: any) => {
    query.sort_by = "created_at"
    let searchParams = new URLSearchParams(query)
    var url =
      process.env.REACT_APP_WAKACJE_API_URL +
      `/products-admin/v1.1/products?${searchParams}`
    var res = await axios.get(url)
    return res.data
  }

  const listTrips = async (query: any) => {
    var url =
      process.env.REACT_APP_WAKACJE_API_URL + "/products-admin/v1.1/trips"
    if (query) {
      url += "?" + new URLSearchParams(query).toString()
    }
    var res = await axios.get(url)
    return res.data
  }
  const getTrip = async (id: any) => {
    var url =
      process.env.REACT_APP_WAKACJE_API_URL + `/products-admin/v1.1/trips/${id}`
    var res = await axios.get(url)
    return res.data
  }
  const updateTrip = async (productId: string, data: any) => {
    var url =
      process.env.REACT_APP_WAKACJE_API_URL +
      `/products-admin/v1.1/products/${productId}/trips/${data.id}`

    const command = {
      type: "Trip.Update",
      aggregateId: data.id,
      payload: {
        ...data
      }
    }
    var res = await axios.post(url, { command })
    return res.data
  }
  return {
    createTrip,
    createProduct,
    updateProduct,
    listProducts,
    superListProducts,
    listTrips,
    getTrip,
    updateTrip,
    runProductCommand,
    runTripCommand
  }
}

export const useTrip = (id: string) => {
  var url =
    process.env.REACT_APP_WAKACJE_API_URL + `/products-admin/v1.1/trips/${id}`
  const [{ data, loading }, refetch] = useAxios(url, { useCache: false })
  return { loading, data, refetch }
}

export const useProduct = (id: string) => {
  var url =
    process.env.REACT_APP_WAKACJE_API_URL +
    `/products-admin/v1.1/products/${id}`
  const [{ data, loading }, refetch] = useAxios(url, { useCache: false })
  return { loading, data, refetch }
}
