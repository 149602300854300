import Button from "@mui/material/Button"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

import { useForm, FormProvider, get, FieldPath } from "react-hook-form"

import { CompanyDetails } from "../../../../types/company"
import {
  Heading2,
  Heading3,
  Subtitle,
  SectionTitle
} from "../../../../components/FormCommons"
import { CompanySectionGeneralForm } from "../form/CompanySectionGeneral"
import { CompanySectionFinanceForm } from "../form/CompanySectionFinance"
import { CompanySectionServicesForm } from "../form/CompanySectionServices"
import {
  PRODUCT_TYPE_CAMP,
  PRODUCT_TYPE_DAYCAMP,
  PRODUCT_TYPE_SCHOOL_TRIP
} from "wakacje-web/src/utils/ProductUtils"
import { FormErrors } from "../../../../components/FormErrors"

type FirstStepValues = Pick<
  CompanyDetails,
  | "nip"
  | "name"
  | "address_string"
  | "city"
  | "postcode"
  | "bank_account"
  | "bank_name"
  | "bank_verified"
  | "logo_image"
>

export const operation_types_prefix = "operation_types_"
type operation_types_keys = `${typeof operation_types_prefix}${string}`

type FirstStepRawValues = FirstStepValues &
  {
    [key in operation_types_keys]: boolean
  }

export const operation_types_mapping: Record<string, string> = {
  "Kolonie i obozy": PRODUCT_TYPE_CAMP.value,
  Półkolonie: PRODUCT_TYPE_DAYCAMP.value,
  "Wycieczki szkolne": PRODUCT_TYPE_SCHOOL_TRIP.value
  // "Zielone szkoły": PRODUCT_TYPE_SCHOOL.value,
}

export function FirstStepSummary({
  values
}: {
  values: FirstStepValues & Pick<CompanyDetails, "operation_types">
}) {
  console.log("FirstStepSummary", values)
  return (
    <section>
      <section className="my-8"></section>
      <section className="my-8">
        <SectionTitle>Zakres działalności</SectionTitle>
      </section>
    </section>
  )
}

const registerFirstStepSchema = yup.object().shape({
  name: yup.string().required("Nazwa firmy jest wymagana"),
  nip: yup.string().required("Nip jest wymagany"),
  city: yup.string().required("Miasto jest wymagane"),
  postcode: yup
    .string()
    .required("Kod pocztowy jest wymagany")
    .matches(/\d{2}-\d{3}/, "Niepoprawny format kodu pocztowego"),
  address_string: yup.string().required("Adres firmy jest wymagany")
})
export function RegisterCompanyFirstStep({ values, onNext, onBack }: any) {
  console.log("RegisterCompanyFirstStep", values)
  const formMethods = useForm<FirstStepRawValues>({
    mode: "onBlur",
    resolver: yupResolver(registerFirstStepSchema),
    defaultValues: values
      ? {
          ...values
        }
      : {}
  })

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors }
  } = formMethods

  console.log("First step", errors)
  const register = (name: FieldPath<FirstStepRawValues>, options?: any) => ({
    ...formMethods.register(name, { ...options }),
    id: name,
    error: Boolean(get(errors, name, false))
  })

  async function onFormSubmit(rawData: FirstStepRawValues) {
    const data: Partial<CompanyDetails> = {
      operation_types: []
    }
    console.log("Register Company first step submit", rawData)
    Object.keys(rawData).forEach((key) => {
      if (
        key.startsWith(operation_types_prefix) &&
        rawData[key as operation_types_keys]
      ) {
        data.operation_types?.push(
          key.substring(operation_types_prefix.length) as string
        )
      } else {
        ;(data as any)[key] = rawData[key as keyof FirstStepValues]
      }
    })
    var isValid = await formMethods.trigger()
    console.log("isValid", isValid)
    if (isValid) {
      onNext(data)
    }
  }
  console.log("errors", errors)
  return (
    <section>
      <Subtitle>Rejestracja konta organizatora</Subtitle>
      <Heading2>Szczegóły</Heading2>
      <FormProvider {...formMethods}>
        <form className="my-8" onSubmit={handleSubmit(onFormSubmit)}>
          <Heading3>Dane podstawowe</Heading3>
          <CompanySectionGeneralForm
            register={register}
            watch={watch}
            setValue={setValue}
            trigger={formMethods.trigger}
          />
          <Heading3>Dane finansowe</Heading3>
          <CompanySectionFinanceForm register={register} control={control} />
          <Heading3>Zakres działalności</Heading3>
          <CompanySectionServicesForm
            register={register}
            control={control}
            watch={watch}
          />

          {/* <Heading3>Logo firmy</Heading3>
          <div className="flex items-center">
            {logoImage && logoImage instanceof File ? (
              <img
                className="w-1/4"
                src={URL.createObjectURL(logoImage)}
                alt="Logo firmy"
              />
            ) : (
              <AccountCircleIcon sx={{ fontSize: 96, opacity: 0.2 }} />
            )}
            <input
              className="hidden"
              ref={fileInputRef}
              type="file"
              accept="image/*"
              onChange={(event) => {
                const files = event.currentTarget.files
                if (files) {
                  const file = files.item(0)
                  if (file) setValue("logo_image", file)
                }
              }}
            />
            <Button
              className="mx-4"
              variant="outlined"
              onClick={() => fileInputRef.current?.click()}
            >
              Dodaj
            </Button>
          </div> */}
          <FormErrors errors={errors}></FormErrors>

          <div className="text-right">
            <Button
              className="mx-4"
              variant="contained"
              type="submit"
              disabled={Object.keys(errors).length > 0}
            >
              Dalej
            </Button>
          </div>
        </form>
      </FormProvider>
    </section>
  )
}
