import { Controller } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { InputField } from "../../../components/FormCommons"
import { InputAdornment } from "@mui/material"

export const CampFeesLastMinute = ({ control }) => {
  const { t } = useTranslation()

  return (
    <>
      <div className="mb-2 mt-6 text-normal font-bold">
        {t("ui.trips.last_minute_price", "Cena last minute")}
      </div>
      <div className="mt-1 text-sm font-light">
        {t(
          "ui.trips.last_minute_description",
          "Cena promocyjna, ustawiona na ostatnie dostępne miejsca, blisko terminu wyjazdu. Można edytować dowolnym momencie."
        )}
      </div>

      <div className="flex flex-col md:flex-row gap-2">
        <Controller
          name="add_term_payments_last_minute"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            const handleValueChange = (event) => {
              const inputValue = parseFloat(event.target.value)
              if (!isNaN(inputValue)) {
                const multipliedValue = inputValue * 100
                onChange(multipliedValue)
              } else {
                onChange(null)
              }
            }

            return (
              <InputField
                type="number"
                label={t("ui.trips.price_per_person", "Cena / 1 osobę")}
                value={value ? value / 100 : ""}
                onChange={handleValueChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">zł</InputAdornment>
                  )
                }}
              />
            )
          }}
        />
        <Controller
          name={"add_term_payments_last_minute_date"}
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            const val =
              value != null && value.length > 10 ? value.substr(0, 10) : value
            return (
              <InputField
                type="date"
                onChange={onChange}
                value={val}
                InputLabelProps={{ shrink: true }}
                label={t("ui.trips.offer_from", "Ofeta obowiazuje od")}
                id={`add_term_payments_last_minute_date`}
              />
            )
          }}
        />
      </div>
    </>
  )
}
