import { Dialog } from "@mui/material"

import { Close } from "@mui/icons-material"
import { useTranslation } from "react-i18next"
import { useCallback, useState } from "react"
import { useSnackbar } from "notistack"
import { useForm, RegisterOptions, FieldPath, get } from "react-hook-form"
import { useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"

import { ProductTypes } from "wakacje-web/src/utils/ProductUtils"

import { TripDataDialogFees } from "./TripDataDialogFees"
import { TripDataDialogButtons } from "./TripDataDialogButtons"
import { TripDataDialogAvailableSpots } from "./TripDataDialogAvailableSpots"
import { TripDataDialogDates } from "./TripDataDialogDates"
import { TripDataDialogName } from "./TripDataDialogName"
import { SectionTitle } from "../FormCommons"
import { getTripSchemaForProductType } from "./tripDataSchema"

export const TripDataDialogMain = ({
  open,
  product,
  onClose,
  headerTitle,
  onSubmit,
  defaultValues
}: {
  open: boolean
  product: any
  onClose: () => void
  headerTitle: string
  onSubmit: (data: any) => void
  defaultValues: any
}) => {
  const productType = product.type ? product.type : product.product_type

  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.up("md"))

  const [isSaving, setIsSaving] = useState(false)

  const { enqueueSnackbar } = useSnackbar()

  const schema = getTripSchemaForProductType(productType)

  const resolver = useCallback(
    async (data) => {
      var res: any
      try {
        const values = await schema.validate(data, {
          abortEarly: false
        })

        res = {
          values,
          errors: {}
        }
      } catch (errors) {
        res = {
          values: {},
          errors: errors.inner.reduce(
            (allErrors, currentError) => ({
              ...allErrors,
              [currentError.path]: {
                type: currentError.type ?? "validation",
                message: currentError.message
              }
            }),
            {}
          )
        }
      }
      return res
    },
    [schema]
  )

  const formMethods = useForm({
    mode: "onBlur",
    resolver: resolver,
    defaultValues
  })

  const register = (name: FieldPath<any>, options?: RegisterOptions) => ({
    ...formMethods.register(name, { ...options }),
    id: name,
    product_id: product.id,
    product_type: product.type,
    error: Boolean(get(errors, name, false))
  })

  const {
    handleSubmit,
    formState: { errors, isValid },
    control
  } = formMethods

  const { t } = useTranslation()

  const onFormSubmit = async (data: any) => {
    if (isSaving) {
      return
    }
    const isValid = await formMethods.trigger()

    if (!isValid) {
      return
    }

    try {
      setIsSaving(true)

      await onSubmit(data)

      enqueueSnackbar(t("ui.trips.success_save", "Zapisano!"), {
        variant: "success"
      })

      setIsSaving(false)
      onClose()
    } catch (err) {
      setIsSaving(false)

      console.error("Error saving")
      enqueueSnackbar(
        t("ui.trips.error_save", "Problem z zapisem ") + (err as any)?.message,
        {
          variant: "error"
        }
      )
    }
  }

  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          maxWidth: md ? "564px" : "300px",
          width: "100%",
          borderRadius: "8px"
        }
      }}
      onClose={onClose}
    >
      {product && (
        <div className="flex flex-col gap-4 pt-6 pb-10 px-10">
          <div className="">
            <div className=" flex justify-end cursor-pointer" onClick={onClose}>
              <Close width={16} />
            </div>
            <section>
              <SectionTitle
                useBlueBackground={productType === ProductTypes.CAMP && false}
              >
                {headerTitle}
              </SectionTitle>
              <form onSubmit={handleSubmit(onFormSubmit)} className="p-2">
                <TripDataDialogName control={control} />

                <TripDataDialogDates control={control} />

                <TripDataDialogAvailableSpots
                  control={control}
                  register={register}
                />

                <TripDataDialogFees control={control} />

                <TripDataDialogButtons
                  isSaving={isSaving}
                  isValid={isValid}
                  onClose={onClose}
                />
              </form>
            </section>
          </div>
        </div>
      )}
    </Dialog>
  )
}
