export class LinkUtils {
  static getAdminHref() {
    if (
      process.env.NEXT_PUBLIC_WEBSITE === "monkeystime.co.uk" ||
      process.env.NEXT_PUBLIC_WEBSITE === "monkeystime.eu"
    ) {
      return "https://admin.monkeystime.co.uk"
    }
    return "https://admin.fajnyczas.pl"
  }
}
