import { useState } from "react"
import { MojeButton } from "../../components/ui/MojeButton"
import { PosterEditor } from "./PosterEditor"
import { PosterUtils, fieldDefinitions } from "./PosterUtils"
import Stepper from "@mui/material/Stepper"
import Step from "@mui/material/Step"
import StepLabel from "@mui/material/StepLabel"
import { useNavigate } from "react-router-dom"
import { usePosterCommand } from "./usePosterOrdersApi"
import { useSnackbar } from "notistack"
import { UidUtils } from "../../utils/uid-utils"
import { useCompanyContext } from "../../contexts/company-context"
import { NewPosterSummaryPage } from "./NewPosterSummaryPage"

import {
  PaymentContextProvider,
  usePaymentContext
} from "wakacje-web/src/shared/payments/PaymentsContext"
import { PaymentPopup } from "wakacje-web/src/shared/payments/PaymentPopup"
import { useAuth } from "wakacje-web/src/auth/AuthContext"

const steps = ["Szablon", "Edycja", "Podsumowanie"]
const Header = ({ activeStep }) => {
  return (
    <div className="w-full mb-8">
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((step) => (
          <Step key={step}>
            <StepLabel>{step}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  )
}

const ImgWrapper = ({ children, selected }) => {
  return (
    <div
      className={`py-2  rounded-xl mr-8 ${
        selected ? " border-blue " : "border-slate-200"
      }`}
    >
      {children}
    </div>
  )
}
const StepSelectPosterType = ({ onTemplateSelected, selectedTemplate }) => {
  const posterTypes = PosterUtils.getPosterTemplates()
  console.log("StepSelectPosterType", selectedTemplate)
  return (
    <div>
      {posterTypes.map((pt) => (
        <div
          className={`mb-8 transition-all cursor-pointer hover:bg-blue-100/30 rounded-xl border-2 
          p-4 ${
            selectedTemplate?.code === pt.code
              ? ` border-blue-500 bg-blue-100/20 `
              : ` border-slate-200`
          }
          `}
          onClick={() => onTemplateSelected(pt)}
        >
          <div className="mb-4 text-xl font-bold ml-2">{pt.name}</div>
          <div className="flex flex-row">
            <ImgWrapper selected={selectedTemplate === pt}>
              <img
                src={`/assets/posters/${pt.posterImgUrl}`}
                className="h-64"
                alt="Poster"
              />
              <div className="text-center text-sm font-bold ">Plakat</div>
            </ImgWrapper>
            <ImgWrapper selected={selectedTemplate === pt}>
              <img
                src={`/assets/posters/${pt.leafletImgUrl}`}
                className="h-64 "
                alt="Ulotka"
              />
              <div className="text-center text-sm font-bold">Ulotka</div>
            </ImgWrapper>
          </div>
        </div>
      ))}
    </div>
  )
}

const NewPosterPageInner = ({ companyId }: { companyId: string }) => {
  const [step, setStep] = useState(0)
  const [template, setTemplate] = useState<any>()
  const navigate = useNavigate()
  const [runCommand, isSaving] = usePosterCommand()
  const [newId, setNewId] = useState(UidUtils.generateUid("pkt_"))
  const companyCtx = useCompanyContext()
  const { enqueueSnackbar } = useSnackbar()
  const paymentContext = usePaymentContext()

  const [newOrder, setNewOrder] = useState<any>({
    delivery_address: companyCtx.company.address_string,
    delivery_city: companyCtx.company.city,
    delivery_postcode: companyCtx.company.postcode,
    company_id: companyCtx.company.id,
    price: 25000,
    data: {
      TITLE: "Tytuł plakat i ulotka",
      PRODUCT_NAME: "Kolonie i obozy",
      PRODUCT_DATE: "Data wydarzenia",
      DESCRIPTION_1: "Opis wydarzenia 1",
      DESCRIPTION_2: "Opis wydarzenia 2",
      PHONE: `tel: ${companyCtx.company.phone}`,
      WWW_URL: `https://www.fajnyczas.pl/${companyCtx.company.fc_website_prefix}`,
      LOGO_URL: companyCtx.company.logo_image_url,
      EVENT_TITLE_1: "Przykładowy termin 1",
      EVENT_DESCRIPTION_1:
        "Opis terminu lorem lorem. Opis terminu lorem lorem. Opis terminu lorem lorem",
      EVENT_TITLE_2: "Przykładowy termin 2",
      EVENT_DESCRIPTION_2:
        "Opis terminu lorem lorem. Opis terminu lorem lorem. Opis terminu lorem lorem.",
      EVENT_TITLE_3: "Przykładowy termin 3",
      EVENT_DESCRIPTION_3:
        "Opis terminu lorem lorem. Opis terminu lorem lorem. Opis terminu lorem lorem.",
      FOOTER_TEXT: `Więcej informacji znajdziesz na https://www.fajnyczas.pl/${companyCtx.company.fc_website_prefix}, tutaj lub pod numerem telefonu: 123 456 678`,
      QR: `https://www.fajnyczas.pl/${companyCtx.company.fc_website_prefix}`
    }
  })

  const handleSetField = (field, value) => {
    // check length
    if (fieldDefinitions[field]?.max) {
      console.log(value.length, " max", fieldDefinitions[field].max)
      if (value.length > fieldDefinitions[field].max) {
        value = value.substr(0, fieldDefinitions[field].max)
      }
    }
    setNewOrder({ ...newOrder, data: { ...newOrder.data, [field]: value } })
  }

  const handleUpdated = (delta) => {
    const _newOrder = { ...newOrder, ...delta }
    _newOrder.total_price = _newOrder.price + (_newOrder.deliery_price ?? 0)
    console.log("_newOrder", _newOrder)
    setNewOrder(_newOrder)
  }
  const handleCreatePosterOrder = async () => {
    console.log("handleCreatePosterOrder", template)
    if (!template) {
      return
    }
    const command = {
      type: "PosterOrder.Create",
      aggregateId: newId,
      payload: {
        id: newId,
        product_name: "Pakiet klasyczny (10x50)",
        product_code: "STANDARD_2022_10_50",
        ...newOrder
      }
    }

    try {
      var res = await runCommand(command)
      console.log(res)
      enqueueSnackbar("Zamówienie plakatow zapisane!", {
        variant: "success"
      })
      setNewOrder(res)
      paymentContext.initPayment({
        type: "COMPANY_POSTER_ORDER",
        poster_order_id: res.id
      })
      // navigate("/plakaty/" + res.id)
    } catch (err) {
      console.error("Error saving", err)
    }
  }

  const handleNext = () => {
    if (step === 0 || step === 1) {
      setStep(step + 1)
    } else if (step === 2) {
      return handleCreatePosterOrder()
    }
  }
  const handleBack = () => navigate("/plakaty")
  const nextLabel = () => {
    if (step === 0) {
      return "Przejdź do edycji"
    }
    if (step === 1) {
      return "Przejdź do podsumowania"
    }
    if (step === 2) {
      return "Przejdź do płatności"
    }
    return "Dalej"
  }
  return (
    <div className="flex flex-col items-center p-8 container max-w-screen-lg m-auto">
      <Header activeStep={step} />
      {step === 0 && (
        <div className="w-full">
          <div className="flex text-xl mb-4 font-lato">
            Wybierz szablon plakatu oraz zaznacz wzór ulotki.
          </div>
          <StepSelectPosterType
            onTemplateSelected={(t) => {
              console.log("sett", t)
              setTemplate(t)
              setNewOrder({
                ...newOrder,
                template_code: t.code
              })
            }}
            selectedTemplate={template}
          />
        </div>
      )}
      {step === 1 && (
        <PosterEditor
          posterTemplate={template}
          onSetField={handleSetField}
          values={newOrder.data}
          editable
        />
      )}
      {step === 2 && (
        <NewPosterSummaryPage
          posterTemplate={template}
          order={newOrder}
          onUpdate={handleUpdated}
          onNext={handleNext}
          onBack={handleBack}
        />
      )}

      {step !== 2 && (
        <div className="flex-row flex w-full">
          <div>
            <MojeButton
              disabled={!template}
              label="Powrót"
              onClick={handleBack}
              variant="text"
              color="secondary"
            />
          </div>

          <div className="flex-1 " />

          <div>
            <MojeButton
              disabled={!template}
              label={nextLabel()}
              onClick={handleNext}
              variant="contained"
              color="primary"
            />
          </div>
        </div>
      )}
    </div>
  )
}

export const NewPosterPage = (props: any) => {
  const { enqueueSnackbar } = useSnackbar()
  const { auth } = useAuth()

  return (
    <PaymentContextProvider
      rootUrl={process.env.REACT_APP_WAKACJE_API_URL}
      enqueueSnackbar={enqueueSnackbar}
      accessToken={auth?.currentUser?.accessToken}
    >
      <NewPosterPageInner {...props} />
      <PaymentPopup />
    </PaymentContextProvider>
  )
}
