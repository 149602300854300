import {
  Dialog,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select
} from '@mui/material'
import axios from 'axios'
import { useEffect, useRef, useState } from 'react'
import { ApiTable, ApiTableRefType } from '../../../components/table/ApiTable'
import Typo from '../../../components/typography'
import { SuperUserPage } from '../SuperUserPage'
import { FormattedDate, FormattedTime } from 'react-intl'
import Toolbar from '../Toolbar/Toolbar'
import { useSearchText } from '../useSearchText'
import { usePackageOrdersApi } from '../usePackageOrders'
import { useCompanyApi } from '../../organizer/events/api/useCompanyApi'
import { ProductTypes } from 'wakacje-web/src/utils/ProductUtils'
import { SearchBox } from '../../../components/table/SearchBox'

const SECOND = 60 * 1000
const HOUR = 60 * SECOND
const DAY = 24 * HOUR
const SEVEN_DAYS = 7 * DAY

function bankAccountFormatter (str) {
  let formattedStr = str.slice(0, 2) + ' '
  for (let i = 2; i < str.length; i += 4) {
    formattedStr += str.slice(i, i + 4) + ' '
  }
  formattedStr = formattedStr.trim()

  return formattedStr
}

function getStatus (status: string) {
  if (status === 'active') {
    return 'Aktywny'
  }

  if (status === 'expires_soon') {
    return 'Wygasa'
  }

  return 'Nieaktywny'
}

export const SuperAdminUsers = ({ accountType }) => {
  const { setSearchTextDebounced, queryParams } = useSearchText()
  const { list: getPackages } = usePackageOrdersApi()
  const { listCompanies } = useCompanyApi()
  const [selected, setSelected] = useState<any>(false)
  const [packages, setPackages] = useState<any>([])
  // const [companies, setCompanies] = useState<any>([])

  const [type, setType] = useState<any>(accountType)
  const [status, setStatus] = useState('all')
  const [administrator, setAdministrator] = useState(undefined)
  const ref = useRef<ApiTableRefType>()

  useEffect(() => {
    const getData = async () => {
      try {
        const packages = await getPackages({}, 1000)
        // const companies = await listCompanies()
        setPackages(packages.data)
        // setCompanies(companies.data);
      } catch (err) {
        console.log("Couldn't fetch packages:", err)
      }
    }
    getData()
  }, [])

  const list = async (query = {}, page: any) => {
    const improvedQuery = { ...query, status, administrator }

    console.log('listApi', improvedQuery, page)
    let searchParams = new URLSearchParams(improvedQuery)

    if (!administrator) {
      searchParams.delete('administrator')
    }

    let url
    if (type === 'USER') {
      searchParams.delete('type')
      url = process.env.REACT_APP_WAKACJE_API_URL + `/v1/super/users`
    } else {
      searchParams.delete('type')
      url = process.env.REACT_APP_WAKACJE_API_URL + `/v1/companies`
    }
    url += '?' + searchParams.toString()

    var res = await axios.get(url)
    return res.data
  }

  const columns = [
    {
      header: 'Rejestracja',
      cell: (row: any) => (
        <div>
          <div className='flex flex-row whitespace-nowrap'>
            <FormattedDate value={row.created_at} />{' '}
            <FormattedTime value={row.created_at} />
          </div>
        </div>
      )
    },
    {
      header: 'Firma',
      cell: (row: any) => (
        <div>
          {row.companies &&
            row.companies.map((c: any) => (
              <div className='underline'>
                {c.fc_website_prefix ? (
                  <a
                    href={`https://www.fajnyczas.pl/${c.fc_website_prefix}`}
                    target='_blank'
                    rel='noreferrer'
                  >
                    {c.name}
                  </a>
                ) : (
                  <>{c.name}</>
                )}
              </div>
            ))}
        </div>
      )
    },
    {
      header: 'Użytkownik',
      cell: (row: any) => <div>{row.name} </div>
    },
    {
      header: 'Telefon',
      cell: (row: any) => <div>{row.phone}</div>
    },
    {
      header: 'Login',
      cell: (row: any) => (
        <>
          <div
            className={`flex flex-row ${
              row.email_verified
                ? ' text-green-800 font-bold '
                : ' text-gray-500 '
            }`}
          >
            {row.email}
          </div>
        </>
      )
    },
    // {
    //   header: "Uwagi Admin",
    //   cell: (row: any) => (
    //     <div>
    //       {row.companies &&
    //         row.companies.map((c: any) => (
    //           <div>{c.admin_comment ? c.admin_comment : ""}</div>
    //         ))}
    //     </div>
    //   )
    // },
    {
      header: 'Licencja do',
      cell: (row: any) => {
        if (row.companies && row.companies.length > 0) {
          const id = row.companies[0].id
          const order = packages.find(el => el.company_id === id)
          return order && order.valid_till ? (
            <div className='whitespace-nowrap'>{order.valid_till}</div>
          ) : (
            <div></div>
          )
        }
        return <div></div>
      }
    },
    {
      header: 'Źródło',
      cell: (row: any) => (
        <div
          title={
            row.type === 'MERCHANT'
              ? 'Pierwsza rejestracja jako firma'
              : 'Pierwsza rejestracja jako rodzic'
          }
        >
          {row.type === 'MERCHANT' ? 'F' : 'R'}
        </div>
      )
    }
    // {
    //   header: "Status",
    //   cell: (row: any) => <div>{}</div>
    // },
    // {
    //   header: "Typ",
    //   cell: (row: any) => <div>{}</div>
    // },
    // {
    //   header: "Opiekun",
    //   cell: (row: any) => <div>{}</div>
    // },
    // {
    //   header: "Nr konta bankowego",
    //   cell: (row: any) => {
    //     if (row.companies && row.companies.length > 0) {
    //       const id = row.companies[0].id
    //       const company = companies.find(el => el.id === id)
    //       return ((company && company.bank_account) ? <div className="whitespace-nowrap">{bankAccountFormatter(company.bank_account)}</div> : <div></div>)
    //     }
    //     return (<div></div>)
    //   }
    // }

    // {
    //   header: "Rodzaj",
    //   cell: (row: any) => <div>{row.is_business ? "F" : "R"}</div>
    // },
  ]
  const columnsCompanies = [
    {
      header: 'Rejestracja',
      cell: (row: any) => (
        <div>
          <div className='flex flex-row whitespace-nowrap'>
            <FormattedDate value={row.created_at} />{' '}
            <FormattedTime value={row.created_at} />
          </div>
        </div>
      )
    },
    {
      header: 'Firma',
      cell: (row: any) => (
        <div className='underline'>
          {row.fc_website_prefix ? (
            <a
              href={`https://www.fajnyczas.pl/${row.fc_website_prefix}`}
              target='_blank'
              rel='noreferrer'
            >
              {row.name}
            </a>
          ) : (
            <>{row.name}</>
          )}
        </div>
      )
    },
    {
      header: 'Użytkownik',
      cell: (row: any) => (
        <div>{row.admin_first_name + ' ' + row.admin_last_name} </div>
      )
    },
    {
      header: 'Telefon',
      cell: (row: any) => <div>{row.phone}</div>
    },
    {
      header: 'Login',
      cell: (row: any) => (
        <>
          <div
            className={`flex flex-row ${
              row.email_verified
                ? ' text-green-800 font-bold '
                : ' text-gray-500 '
            }`}
          >
            {row.email}
          </div>
        </>
      )
    },
    {
      header: 'Uwagi Admin',
      cell: (row: any) => <div>{row.admin_comment ?? ''}</div>
    },
    {
      header: 'Licencja do',
      cell: (row: any) => (
        <div className='whitespace-nowrap'>{row.license_valid_till ?? ''}</div>
      )
    },
    {
      header: 'Status',
      cell: (row: any) => {
        return <div>{getStatus(row.status)}</div>
      }
    },
    {
      header: 'Typ',
      cell: (row: any) => (
        <div>{row.company_type ? 'Firma transportowa' : ''}</div>
      )
    },
    {
      header: 'Opiekun',
      cell: (row: any) => <div>{row.administrator ?? ''}</div>
    },
    {
      header: 'Nr konta bankowego',
      cell: (row: any) => (
        <div className='whitespace-nowrap'>
          {bankAccountFormatter(row.bank_account ?? '')}
        </div>
      )
    }
  ]

  if (type && type !== 'ALL') {
    queryParams.type = type
  }

  return (
    <>
      <Toolbar setSearchTextDebounced={setSearchTextDebounced} api={[]}>
        <div className='flex flex-row gap-2 items-center'>
          <div>Status</div>
          <Select
            className='ml-4'
            value={status}
            inputProps={{
              'aria-labelledby': 'table-page-select-label'
            }}
            onChange={e => setStatus(e.target.value)}
            MenuProps={{
              PaperProps: {
                sx: {
                  width: 'min-content'
                }
              }
            }}
          >
            <MenuItem value={'all'}>Wszystkie</MenuItem>
            <MenuItem value={'active'}>Aktywny</MenuItem>
            <MenuItem value={'expires_soon'}>Wygasa</MenuItem>
            <MenuItem value={'expired'}>Nieaktywny</MenuItem>
          </Select>
        </div>

        <div className='flex flex-row gap-2 items-center'>
          <SearchBox
            setSearchTextDebounced={() => undefined}
            setSearchValue={setAdministrator}
            label={'Administrator:'}
          />
        </div>
      </Toolbar>

      <ApiTable
        ref={ref}
        columns={type === 'USER' ? columns : columnsCompanies}
        listApi={list}
        showExpandColumn
        onRowClicked={row => {
          setSelected(row)
        }}
        queryParams={queryParams}
        initialSort={{ direction: 'desc', field: 'created_at' }}
      />

      <Dialog
        maxWidth='md'
        open={selected}
        onClose={() => setSelected(false)}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <DialogTitle>
          <Typo.Title32Bold>Użytkownik</Typo.Title32Bold>
        </DialogTitle>
        <DialogContent>
          {selected && <SuperUserPage user={selected} />}
        </DialogContent>
      </Dialog>
    </>
  )
}
