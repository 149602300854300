import {
  Button,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup
} from "@mui/material"
import { useEffect } from "react"
import { Controller, useFormContext } from "react-hook-form"
import {
  FormHelperText,
  InputField,
  SectionTitle
} from "../../../../components/FormCommons"

import { SectionContainer } from "./CreateOfferForm"
import { useTranslation } from "react-i18next"

function FormSectionTransportation({ isIndividualOffer = false }) {
  const { t } = useTranslation("common")
  const { watch, getValues, register, control, unregister } =
    useFormContext<any>()
  const values = getValues()
  const transportTypeWatch = watch("transport_type")

  useEffect(() => {
    if (transportTypeWatch === "own") {
      unregister("transport_vehicle")
      unregister("transport_price_per_pax")
    }
  }, [transportTypeWatch])

  return (
    <SectionContainer>
      <SectionTitle>Dojazd</SectionTitle>
      <Controller
        name="transport_type"
        control={control}
        render={({
          field,
          fieldState: { invalid, isTouched, isDirty, error },
          formState
        }) => (
          <RadioGroup {...field}>
            <FormControlLabel
              label={t("event_filter.drive_options.own", "Własny")}
              value="own"
              control={<Radio />}
            />
            <FormControlLabel
              label={t("event_filter.drive_options.provided", "Zorganizowany")}
              value="provided"
              control={<Radio />}
            />
          </RadioGroup>
        )}
      />
      {transportTypeWatch === "provided" && (
        <>
          <div className="flex gap-5 items-center">
            <div className="w-[220px]">
              <InputField
                select
                label="Rodzaj transportu*"
                SelectProps={{
                  defaultValue: values?.transport_vehicle || ""
                }}
                {...register("transport_vehicle")}
              >
                <MenuItem value="bus">Autokar</MenuItem>
                <MenuItem value="plane">Samolot</MenuItem>
                <MenuItem value="train">Pociąg</MenuItem>
              </InputField>
            </div>

            {!isIndividualOffer && (
              <div>
                <InputField
                  type="number"
                  label="Cena/1 osobę*"
                  {...register("transport_price_per_pax", {
                    valueAsNumber: true
                  })}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">zł</InputAdornment>
                    )
                  }}
                />
              </div>
            )}
          </div>
          {isIndividualOffer && (
            <FormHelperText>
              Jeśli dojazd wchodzi w cenę wyjazdu wpisz w pole cena 0 zł
            </FormHelperText>
          )}
          {isIndividualOffer && (
            <div className="flex flex-row mb-1 items-center">
              <div className="flex-1">
                <InputField
                  label="Miejsce wyjazdu*"
                  {...register(`pickup_route_address`)}
                />
              </div>
              <div className="w-64 ml-3">
                <InputField
                  type="number"
                  label="Cena*"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">zł</InputAdornment>
                    )
                  }}
                  {...register(`pickup_route_price`)}
                />
              </div>
              {/* <div className="ml-1">
                  <CancelOutlinedIcon onClick={onRemove} />
                </div> */}
            </div>
          )}
        </>
      )}
    </SectionContainer>
  )
}

export default FormSectionTransportation
