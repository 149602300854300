import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm, FormProvider, Controller } from "react-hook-form"
import Button from "@mui/material/Button"
import {
  FormHelperText,
  Heading3,
  InputField
} from "../../../components/FormCommons"
import { CircularProgress } from "@mui/material"
import { FormErrors } from "../../../components/FormErrors"

import { useSnackbar } from "notistack"
import { UidUtils } from "wakacje-web/src/utils/UidUtils"
import { useNavigate, useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { useCompanyContext } from "../../../contexts/company-context"
import { LoadingSpinner } from "../../../components/LoadingSpinner"

import { ProductSectionPhotosForm } from "../products/forms/ProductSectionPhotos"
import { useAuthContext } from "../../../contexts/auth-context"
import { usePlacesApi } from "../places/usePlacesApi"
import { BackButton } from "../../../components/commons/button"
import axios from "axios"
import { useAuth } from "wakacje-web/src/auth/AuthContext"
import { BlueBackground } from "wakacje-web/src/shared/components/BlueBackground"
import { ProductSectionAgeForm } from "../products/forms/ProductSectionAge"
import { SchoolTripIndividualSectionLocation } from "../products/forms/SchoolTripIndividualSectionLocation"
import { GeneralInfoIndividualSchoolTripOffer } from "./GeneralInfoIndividualSchoolTripOffer"
import { IndividualSchoolTripSectionSchedule } from "./IndividualSchoolTripSectionSchedule"
import FormSectionPlaces from "../../../domains/offer/components/CreateOfferForm/FormSectionPlaces"
import FormSectionPrice from "../../../domains/offer/components/CreateOfferForm/FormSectionPrice"
import { IndividualSchoolTripOfferPriceSection } from "./IndividualSchoolTripOfferPriceSection"
import { useProductApi } from "../events/api/useProductApi"
import { getLocalizedKey } from "../../../utils/getLocalizedKey"

const tripSchema = yup.object().shape({
  start_date: yup.string().required("Data jest wymagana"),
  end_date: yup.string().required("Data jest wymagana"),
  name: yup.string().required("Nazwa wycieczki jest wymagana"),
  description: yup.string().required("Opis wycieczki jest wymagany"),
  min_age: yup.string().required("Wiek uczestników jest wymagany"),
  max_age: yup.string().required("Wiek uczestników jest wymagany"),
  country_code: yup.string().required("Kraj jest wymagany"),
  full_price: yup
    .number()
    .required("Cena jest wymagana")
    .moreThan(0, "Cena musi być większa niż 0")
})

export function AddIndividualSchoolTripOffer({
  isUpdateForm,
  type,
  submitRedirect,
  setShowForm
}: {
  isUpdateForm?: boolean
  type?: any // "place" | "accommodation"
  submitRedirect?: boolean
  setShowForm?: Function
}) {
  let { id, chat_hash, trip_id } = useParams()

  const { auth } = useAuth()
  const [loading, setLoading] = useState(false)
  const [inquiry, setInquiry] = useState<any>()
  const [tripToUpdate, setTripToUpdate] = useState(null)
  const { createProduct, updateProduct } = useProductApi()
  const { listProducts } = useProductApi()

  const [isSaving, setIsSaving] = useState(false)
  const authContext = useAuthContext()
  const isSuperAdmin = authContext.isSuperAdmin

  const companyContext = useCompanyContext()

  const api = usePlacesApi()

  const nameKey = getLocalizedKey("name")
  const descriptionKey = getLocalizedKey("description")
  const foodDescriptionKey = getLocalizedKey("food_description")
  const insuranceDescriptionKey = getLocalizedKey("insurance_description")
  const participationDescriptionKey = getLocalizedKey(
    "participation_description"
  )

  const formMethods = useForm({
    mode: "onChange",
    resolver: yupResolver(tripSchema),
    defaultValues: {
      start_date: " ",
      end_date: " ",
      name: " ",
      description: " ",
      description_en: " ",
      min_age: "",
      max_age: "",
      media: [],
      country_code: "PL",
      location: { city: " ", province: " ", driving_directions: null },
      accommodation: null,
      insurance_type: "",
      insurance_description: " ",
      insurance_description_en: " ",
      files: [],
      food_type: "",
      food_description: " ",
      food_description_en: " ",
      food_diets: null,
      participation_description: " ",
      participation_description_en: " ",
      pickup_collection_address: "",
      pickup_collection_date: " ",
      pickup_collection_place: " ",
      pickup_collection_time: null,
      pickup_return_address: " ",
      pickup_return_date: " ",
      pickup_return_place: " ",
      pickup_return_time: null,
      pickup_route_address: " ",
      pickup_route_price: " ",
      advance_price: " ",
      price_description: " ",
      full_price: " ",
      payment_due_date: null,
      price_includes_text: " ",
      price_not_includes_text: " ",
      schedule: [],
      transport_type: " ",
      transport_vehicle: " ",
      trips: [
        {
          full_price: " ",
          advance_price: " ",
          payment_due_date: null
        }
      ],
      places: []
    }
  })

  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const {
    handleSubmit,
    register,
    control,
    watch,
    setValue,
    getValues,
    formState: { errors, isValid }
  } = formMethods
  let resTrip
  const refreshData = async () => {
    if (trip_id) {
      const url =
        process.env.REACT_APP_WAKACJE_HTTP_API_URL +
        `/v1.1/next/products/${trip_id}`

      try {
        resTrip = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${auth.currentUser.accessToken}`
          }
        })

        setTripToUpdate(resTrip?.data)
      } catch (err) {
        console.log("error", err)
      }

      const updateFormValues = () => {
        if (!resTrip?.data) {
          return
        }
        const currentFormValues = getValues()

        const trip = resTrip?.data?.trips?.find((el) => el.id === trip_id)

        if (trip) {
          setValue("full_price", trip.full_price)
          setValue("advance_price", trip.advance_price)
          setValue("payment_due_date", trip.payment_due_date)
          //  setValue("trips", [{full_price: tripPrice, advance_price: trip.advance_price, payment_due_date: trip.payment_due_date }])
        }

        Object.keys(resTrip.data).forEach((key) => {
          if (
            resTrip.data.hasOwnProperty(key) &&
            currentFormValues.hasOwnProperty(key) &&
            (typeof resTrip.data[key] === "number" ||
              typeof resTrip.data[key] === "string")
          ) {
            setValue(key as any, resTrip.data[key])
          }
        })
      }

      updateFormValues()
    }
    const url =
      process.env.REACT_APP_WAKACJE_HTTP_API_URL + `/inquiries/v1/${chat_hash}`

    const urlProduct =
      process.env.REACT_APP_WAKACJE_HTTP_API_URL + `/v1.1/next/products/${id}`
    setLoading(true)
    try {
      let resInquiry = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${auth.currentUser.accessToken}`
        }
      })
      let resProduct = await axios.get(urlProduct, {
        headers: {
          Authorization: `Bearer ${auth.currentUser.accessToken}`
        }
      })
      setInquiry(resInquiry.data)
      setValue("start_date", resInquiry.data.start_date)
      setValue("end_date", resInquiry.data.end_date)
      setValue("description", resProduct.data.description)
      setValue("description_en", resProduct.data.description_en)
      setValue("min_age", resProduct.data.min_age)
      setValue("max_age", resProduct.data.max_age)
      setValue("media", resProduct.data.media)
      setValue("places", resProduct.data.places)
      setValue("accommodation", resProduct.data.accommodation)
      setValue("schedule", resProduct.data.schedule)
      setValue("files", resProduct.data.files)
      setValue("food_type", resProduct.data.food_type)
      setValue("food_description", resProduct.data.food_description)
      setValue("food_description_en", resProduct.data.food_description_en)
      setValue("food_diets", resProduct.data.food_diets)
      setValue("insurance_type", resProduct.data.insurance_type)
      setValue("insurance_description", resProduct.data.insurance_description)
      setValue(
        "insurance_description_en",
        resProduct.data.insurance_description_en
      )
      setValue(
        "participation_description",
        resProduct.data.participation_description
      )
      setValue(
        "participation_description_en",
        resProduct.data.participation_description_en
      )
      setValue("price_description", resProduct.data.price_description)
      setValue("price_includes_text", resProduct.data.price_includes_text)
      setValue(
        "price_not_includes_text",
        resProduct.data.price_not_includes_text
      )
      setValue("country_code", resProduct.data.country_code)
      setValue("location.province", resProduct.data?.location?.province)
      setValue("location.city", resProduct.data?.location?.city)
      if (resProduct.data?.location?.driving_directions) {
        setValue(
          "location.driving_directions",
          resProduct.data?.location?.driving_directions
        )
      }
    } catch (err) {
      console.error("Error loading Chats")
    }
    setLoading(false)
  }
  useEffect(() => {
    refreshData()
  }, [])

  const onFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    if (event) {
      if (typeof event.preventDefault === "function") {
        event.preventDefault()
      }
      if (typeof event.stopPropagation === "function") {
        event.stopPropagation()
      }
    }

    return handleSubmit(async (data: any) => {
      const idIN = UidUtils.generateUid("IN")
      const {
        name_en,
        description_en,
        food_description_en,
        insurance_description_en,
        participation_description_en,
        ...restData
      } = data
      const newTrip: any = {
        id: idIN,
        ...restData,
        name: data[nameKey],
        description: data[descriptionKey],
        food_description: data[foodDescriptionKey],
        insurance_description: data[insuranceDescriptionKey],
        participation_description: data[participationDescriptionKey],
        type: "dedykowana-wycieczka-szkolna",
        company_id: inquiry.company_id,
        dedicated_trip_caregiver_user_id: inquiry.user_id,
        dedicated_trip_caregiver_user_name: inquiry.user_name,
        category: { id: "dedykowana-wycieczka-szkolna" },
        slug: `${id}/${chat_hash}`
      }
      console.log("SUBMIT", newTrip)

      if (isValid) {
        try {
          console.log("form submit")
          setIsSaving(true)
          let res = await createProduct(newTrip)
          console.log("Save place result", res)
          if (res.success) {
            enqueueSnackbar("Wycieczka zapisana", { variant: "success" })
            setIsSaving(false)
            navigate("/products/schooltrip/planned-schooltrips")
            if (setShowForm) {
              setShowForm(false)
            }
          }
        } catch (err) {
          setIsSaving(false)
          enqueueSnackbar("Błąd zapisania wycieczki", { variant: "error" })
          console.error("Error saving place", err)
        }
      }
    })(event)
  }

  const handleCancel = () => {
    if (setShowForm) {
      setShowForm(false)
    }
    navigate("/inquiries")
  }

  async function onFormUpdate(data: any) {
    console.log("UPDATE", data)
    const {
      name_en,
      description_en,
      food_description_en,
      insurance_description_en,
      participation_description_en,
      ...restData
    } = data
    const updatePlace: any = {
      id: trip_id,
      ...restData,
      name: data[nameKey],
      description: data[descriptionKey],
      food_description: data[foodDescriptionKey],
      insurance_description: data[insuranceDescriptionKey],
      participation_description: data[participationDescriptionKey],
      type: "dedykowana-wycieczka-szkolna",
      company_id: inquiry.company_id,
      dedicated_trip_caregiver_user_id: inquiry.user_id,
      dedicated_trip_caregiver_user_name: inquiry.user_name,
      category: { id: "dedykowana-wycieczka-szkolna" },
      slug: `${id}/${chat_hash}`,
      transport_vehicle:
        data.transport_type === "own" ? null : data.transport_vehicle,
      pickup_route_address:
        data.transport_type === "own" ? null : data.pickup_route_address,
      pickup_route_price:
        data.transport_type === "own" ? null : data.pickup_route_price
      // trips: [
      //   {
      //     ...(data.trips?.[0] || {}),
      //     full_price: data.full_price
      //   }
      // ]
    }
    if (isValid) {
      console.log("form update", updatePlace)
      setIsSaving(true)
      try {
        console.log("reached on form update")
        setIsSaving(true)
        let res = await updateProduct(updatePlace)

        if (res.status === true) {
          setIsSaving(false)
          enqueueSnackbar("Zaktualizowano wycieczkę", { variant: "success" })
          navigate("/products/schooltrip/planned-schooltrips")
        }
      } catch (err) {
        setIsSaving(false)
        enqueueSnackbar("Błąd aktualizacji wycieczki", { variant: "error" })
        console.error("Error update trip", err)
      }
    }
  }

  if (inquiry || (!isUpdateForm && !loading)) {
    return (
      <main className="p-4 flex-auto max-w-screen-xl">
        <BackButton destination={"wycieczka-szkolna"} />
        <div className="max-w-screen-md mx-auto my-8 ">
          <div className="flex gap-3 items-center">
            Utwórz dedykowaną ofertę dla:
            <div className="font-bold text-[32px] ">{inquiry?.user_name}</div>
          </div>
          <div className="flex gap-3 items-center">
            Na podstawie oferty:
            <div className="font-bold text-[32px] ">
              {inquiry?.product?.name}
            </div>
          </div>
          <FormProvider {...formMethods}>
            <form
              className="my-9 "
              onSubmit={trip_id ? handleSubmit(onFormUpdate) : onFormSubmit}
            >
              <section>
                <BlueBackground>
                  <Heading3 id="h3-1">Opis</Heading3>
                </BlueBackground>
                <GeneralInfoIndividualSchoolTripOffer
                  register={register}
                  control={control}
                />

                <BlueBackground>
                  <Heading3 id="h3-1">Lokalizacja</Heading3>
                </BlueBackground>

                <SchoolTripIndividualSectionLocation
                  control={control}
                  register={register}
                  values={{}}
                  watch={formMethods.watch}
                  errors={errors}
                />

                <BlueBackground>
                  <Heading3 id="h3-1">Plan wydarzenia</Heading3>
                </BlueBackground>
                <IndividualSchoolTripSectionSchedule
                  register={register}
                  formMethods={formMethods}
                  values={{}}
                />

                <FormSectionPlaces />
                <BlueBackground>
                  <Heading3 id="h3-1">Cennik</Heading3>
                </BlueBackground>
                <IndividualSchoolTripOfferPriceSection
                  disabled={!!trip_id}
                  register={register}
                  control={control}
                />

                {/* <FormSectionSeparator /> */}
              </section>
              <FormErrors errors={errors}></FormErrors>

              <div className="flex flex-row">
                <div className="">
                  <Button
                    className="mx-4"
                    variant="text"
                    type="button"
                    size="large"
                    onClick={handleCancel}
                  >
                    Anuluj
                  </Button>
                </div>
                {/* <div>
                  {id && (
                    <Button
                      className="mx-4"
                      variant="text"
                      type="button"
                      size="large"
                      onClick={handleRemove}
                    >
                      Usuń miejsce
                    </Button>
                  )}
                </div> */}
                <div className="flex-1" />
                <div className="text-right">
                  <Button
                    className="mx-4"
                    variant="contained"
                    type="submit"
                    size="large"
                    disabled={!isValid}
                  >
                    {isSaving ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      "Zapisz"
                    )}
                  </Button>
                </div>
              </div>
            </form>
          </FormProvider>
        </div>
      </main>
    )
  }

  return (
    <div className="flex justify-center pt-[100px]">
      <LoadingSpinner />
    </div>
  )
}
