import { Box } from "@mui/material"
import { WakacjeInfoCard } from "../../../../../components/WakacjeInfoCard"
import { formatDistance, parse } from "date-fns"
import { pl } from "date-fns/locale"
import { ProductUtils } from "wakacje-web/src/utils/ProductUtils"

export const TripHeader = ({ trip }: { trip: any }) => {
  console.log("TRip Haader ", trip)
  const daysToCome = trip.start_date
    ? formatDistance(
        parse(trip.start_date, "yyyy-MM-dd", new Date()),
        new Date(),
        { locale: pl }
      )
    : "-"

  var paymentsLabel = trip.bookings_stats?.total_paid_amount
    ? ProductUtils.formatPriceInCents(trip.bookings_stats?.total_paid_amount)
    : ""
  if (trip.confirmed_bookings_payments_amount) {
    paymentsLabel +=
      "/" + ProductUtils.formatPriceInCents(trip.bookings_stats?.total_price)
  }
  var participantsCountLabel = trip.bookings_stats?.participants_count ?? "0"
  if (trip.available_spots > 0) {
    participantsCountLabel += "/" + trip.available_spots
  }
  // console.log("Trip header stats", trip)
  return (
    <div className="flex flex-row">
      {/* <WakacjeInfoCard
          label="Liczba uczestników"
          value={participantsCountLabel}
        /> */}

      {trip?.bookings_stats?.total_price && (
        <WakacjeInfoCard
          label="Suma opłat"
          value={`${ProductUtils.formatPriceInCents(
            trip?.bookings_stats?.total_paid_amount ?? 0
          )} / ${ProductUtils.formatPriceInCents(
            trip?.bookings_stats?.total_price ?? 0
          )}`}
          width={240}
        />
      )}
      <WakacjeInfoCard
        label="Termin"
        value={ProductUtils.getTripDatesRange(trip)}
        width={240}
      />
      {/* <WakacjeInfoCard label="Dni do rozpoczęcia" value={daysToCome} /> */}
    </div>
  )
}
