import React from "react"
import { useProductApi } from "../events/api/useProductApi"
import { getLocalizedKey } from "../../../utils/getLocalizedKey"
import { useTranslation } from "react-i18next"
import { EditableFormSection } from "../../../components/forms/EditableFormSection"
import * as Yup from "yup"
import {
  ProductSectionDescriptionForm,
  ProductSectionDescriptionPreview
} from "./ProductSectionDescription"
import {
  ProductSectionAccommodationForm,
  ProductSectionAccommodationPreview
} from "./ProductSectionAccommodation"
import {
  ProductSectionLocationForm,
  ProductSectionLocationPreview
} from "../products/forms/ProductSectionLocation"
import {
  ProductSectionPriceForm,
  ProductSectionPricePreview
} from "./ProductSectionPrice"
import {
  ProductSectionPlacesForm,
  ProductSectionPlacesPreview
} from "../products/forms/ProductSectionPlace/ProductSectionPlace"
import { SectionTitle } from "../../../components/FormCommons"

export const productGroupsAccommodationSchema = {
  [getLocalizedKey("name")]: Yup.string().required(
    "Nazwa oferty jest wymagana"
  ),
  object_type: Yup.array()
    .nullable()
    .default([])
    .required("Kategorie są wymagane")
    .test("non-empty", "Kategorie są wymagane", function (value) {
      return Array.isArray(value) && value.length > 0
    }),
  [getLocalizedKey("description")]: Yup.string().required(
    "Opis oferty jest wymagany"
  ),

  country: Yup.string().required("Kraj jest wymagany"),
  location: Yup.object().when("country_is_poland", {
    is: "yes",
    then: Yup.object({
      province: Yup.string().required("Province is required"),
      city: Yup.string().required("City is required"),
      driving_directions: Yup.string()
    }),
    otherwise: Yup.object({
      province: Yup.string(),
      city: Yup.string().required("City is required"),
      driving_directions: Yup.string()
    })
  }),
  group_min_price: Yup.number()
    .min(0.01, "Cena minimalna grupy musi być większa niż 0")
    .required("Wymagana jest cena minimalna dla grupy"),
  individual_min_price: Yup.number()
    .min(0.01, "Indywidualna cena minimalna musi być większa niż 0")
    .required("Wymagana jest indywidualna cena min")
}

const ProductJoinedSectionsGroupsAccommodation = ({
  values,
  refetch,
  watch
}: any) => {
  const { updateProduct } = useProductApi()

  const nameKey = getLocalizedKey("name")
  const descriptionKey = getLocalizedKey("description")
  const foodDescriptionKey = getLocalizedKey("food_description")

  const saveHandler = async (data: any) => {
    console.log("Save handler", data)
    var productData: any = {
      id: values.id,
      name: data[nameKey],
      description: data[descriptionKey],
      media: data.media,
      object_type: data.object_type,
      facility_amenities: data.facility_amenities,
      food_type: data.food_type,
      food_diets: data.food_diets,
      food_description: data[foodDescriptionKey],
      //  ProductSectionAccommodationview
      max_guests: data.max_guests,
      facility: data.facility,
      //  ProductSectionLocationView
      country_code: data.country_code,
      country_name: data.country_name,
      location: data.location,
      location_types: data.location_types,
      location_name: data.location_name,
      //   ProductSectionPriceView
      individual_min_price: data.individual_min_price,
      group_min_price: data.group_min_price,
      price_description: data.price_description,
      prepayment: data.prepayment,
      prepayment_description: data.prepayment_description,
      tourist_tax: data.tourist_tax,
      tourist_tax_description: data.tourist_tax_description,
      dependent_price: data.dependent_price,
      dependent_price_description: data.dependent_price_description,
      cancellation_fee: data.cancellation_fee,
      cancellation_fee_description: data.cancellation_fee_description,
      //   ProductSectionPlacesView
      places: data.places
    }

    return updateProduct(productData)
  }
  const handleRefresh = () => {
    setTimeout(() => {
      refetch()
    }, 3000)
  }

  const initialValues = { ...values }
  const { t } = useTranslation()

  return (
    <>
      <EditableFormSection
        title={t("product.description", "Opis")}
        values={values}
        onSave={saveHandler}
        onSaved={handleRefresh}
        validationSchema={productGroupsAccommodationSchema}
        fullWidth
        stickyButtons
      >
        {(builderProps: any) => {
          return (
            <div>
              {builderProps.isEditing && (
                <>
                  <ProductSectionDescriptionForm
                    values={initialValues}
                    {...builderProps}
                  />
                  <SectionTitle>
                    {t("ui.groups.holiday_lettings", "Miejsca noclegowe")}
                  </SectionTitle>
                  <ProductSectionAccommodationForm
                    values={initialValues}
                    {...builderProps}
                  />
                  <SectionTitle>
                    {t("ui.groups.location", "Lokalizacja")}
                  </SectionTitle>
                  <ProductSectionLocationForm
                    values={initialValues}
                    {...builderProps}
                  />
                  <SectionTitle>
                    {t("ui.groups.price_and_payments", "Cena i płatności")}
                  </SectionTitle>
                  <ProductSectionPriceForm
                    values={initialValues}
                    {...builderProps}
                  />
                  <SectionTitle>
                    {t("ui.groups.attractions", "Atrakcje")}
                  </SectionTitle>
                  <ProductSectionPlacesForm />
                </>
              )}
              {!builderProps.isEditing && (
                <>
                  <ProductSectionDescriptionPreview values={values} />
                  <SectionTitle>
                    {t("ui.groups.holiday_lettings", "Miejsca noclegowe")}
                  </SectionTitle>
                  <ProductSectionAccommodationPreview values={values} />
                  <SectionTitle>
                    {t("ui.groups.location", "Lokalizacja")}
                  </SectionTitle>
                  <ProductSectionLocationPreview values={values} />
                  <SectionTitle>
                    {t("ui.groups.price_and_payments", "Cena i płatności")}
                  </SectionTitle>
                  <ProductSectionPricePreview values={values} />
                  <SectionTitle>
                    {t("ui.groups.attractions", "Atrakcje")}
                  </SectionTitle>
                  <ProductSectionPlacesPreview values={values} />
                </>
              )}
            </div>
          )
        }}
      </EditableFormSection>
    </>
  )
}

export default ProductJoinedSectionsGroupsAccommodation
