import { useState } from "react"
import { useCompanyApi } from "../events/api/useCompanyApi"
import { useSnackbar } from "notistack"
import { Heading2, Subtitle } from "../../../components/FormCommons"
import Button from "@mui/material/Button"
import { Box } from "@mui/system"
import { CompanySectionGeneral } from "./form/CompanySectionGeneral"
import { CompanySectionFinance } from "./form/CompanySectionFinance"
import { CompanySectionServices } from "./form/CompanySectionServices"
import { CompanySectionAdminContactDetails } from "./form/CompanySectionAdminContactDetails"
import { CompanySectionContactDetails } from "./form/CompanySectionContactDetails"
import { useNavigate } from "react-router"
import { MojeButton } from "../../../components/ui/MojeButton"
import { useCompanyContext } from "../../../contexts/company-context"
import { useAuthContext } from "../../../contexts/auth-context"
import { CompanySectionSuperAdminView } from "../products/CompanySectionSuperAdmin"
import { useTranslation } from "react-i18next"

export function CompanySummary({
  values,
  refetch,
  updateValues
}: {
  values: any
  refetch: any
  updateValues?: Function
}) {
  console.log("CompanySummary", values, updateValues)
  const authContext = useAuthContext()

  return (
    <Box
      sx={{
        display: "flex",
        gap: "3rem",
        justifyContent: "flex-start"
      }}
    >
      <Box width={500}>
        <CompanySectionGeneral
          values={values}
          refetch={refetch}
          onSave={updateValues}
        />
        <CompanySectionFinance
          values={values}
          refetch={refetch}
          onSave={updateValues}
        />
        <CompanySectionServices
          values={values}
          refetch={refetch}
          onSave={updateValues}
        />
        <section className="my-8">
          <CompanySectionAdminContactDetails
            values={values}
            refetch={refetch}
            onSave={updateValues}
          />
        </section>
        <section className="my-8">
          <CompanySectionContactDetails
            values={values}
            refetch={refetch}
            onSave={updateValues}
          />
        </section>
      </Box>
      <Box width={500}>
        {authContext.isSuperAdmin && (
          <CompanySectionSuperAdminView values={values} refetch={refetch} />
        )}
      </Box>
    </Box>
  )
}
export function CompanyRegistrationSummary({
  values,
  onBack,
  onSaved,
  updateValues
}: {
  values: any
  onBack: () => void
  onSaved?: any
  updateValues
}) {
  const { createCompany } = useCompanyApi()
  const [isSaving, setIsSaving] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const companyContext = useCompanyContext()
  const { t } = useTranslation()
  const hostname = window.location.hostname.toLowerCase()
  const country = hostname.includes("fajnyczas.pl") ? "pl" : "en"

  console.log("CompanyRegistrationSummary", values)
  const doSave = async () => {
    console.log("doSave")
    setIsSaving(true)
    try {
      const data = {
        ...values,
        country: country
      }

      var res = await createCompany(data)
      console.log("Do save result", res)
      if (res.success) {
        if (onSaved) {
          await onSaved()
        } else {
          enqueueSnackbar("Firma zapisana!", {
            variant: "success"
          })
          companyContext.refreshHomeData()
          navigate("/")
        }
      } else {
        enqueueSnackbar("Problem z zapisaniem firmy:" + res.message, {
          variant: "error"
        })
      }
    } catch (err) {
      console.error("Error", err)
    }
  }
  return (
    <Box maxWidth={670} margin="auto">
      <section>
        <Subtitle>Rejestracja konta organizatora</Subtitle>
        <Heading2>Podsumowanie</Heading2>
        <div className="mb-8" />
        <CompanySummary
          values={values}
          refetch={() => {}}
          updateValues={updateValues}
        />

        <Box display="flex" flexDirection="row">
          <Button className="mx-4" onClick={onBack}>
            {t("back", "Powrót")}
          </Button>
          <Box flex={1} />
          <div className="mx-4">
            <MojeButton
              variant="contained"
              onClick={doSave}
              isSaving={isSaving}
              label="Zapisz i zakończ rejestrację"
            />
          </div>
        </Box>
      </section>
    </Box>
  )
}
