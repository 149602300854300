import { CircularProgress } from "@mui/material"
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"
import Page from "./components/Page"
import Organizer from "./pages/organizer"
import { TripPage } from "./pages/organizer/events/event/TripPage"
import { LoginPage } from "./pages/login/LoginPage"
import { CompanyRegistrationPage } from "./pages/login/CompanyRegistrationPage"
import { SuperAdminIndex } from "./pages/super/SuperAdminIndex"
import { RegisterCompany } from "./pages/organizer/company/RegisterCompany"
import { SandboxIndex } from "./pages/sandbox/SandboxIndex"
import { Student } from "./pages/sandbox/student/Student"
import { NewUserWelcomeIndex } from "./pages/welcome/NewUserWelcomeIndex"
import Test from "./pages/test"
import { useAuthContext } from "./contexts/auth-context"
import "./index.css"
import { OnboardingIndex } from "./pages/onboarding/OnboardingIndex"
import {
  CompanyContext,
  CompanyContextProvider,
  CompanyContextType
} from "./contexts/company-context"
import { RouteScreen } from "./pages/organizer/Organizer"
import { ComingSoonWidget } from "./components/widgets/ComingSoonWidget"
import SanboxTesting from "./pages/sandbox/SandboxTesting"
import { UserProfilePage } from "./pages/organizer/company/UserProfilePage"
import { WsContextProvider } from "wakacje-web/src/shared/contexts/ws-context"
import { CompanyHomeContextProvider } from "./contexts/company-home-context"
import { FileUploadedContextProvider } from "wakacje-web/src/contexts/FileUploadedContext"
import RegulaminIndex from "./pages/organizer/booking/regulamin/RegulaminIndex"

// const WithRequiredOnboarding = ({ children, onboardingProgress, minStep }) => {
//   if (onboardingProgress > minStep) {
//     return children
//   }
//   return <OnboardingIndex />
// }

const AuthorizedRouter = () => {
  return (
    <BrowserRouter>
      <CompanyContextProvider>
        <CompanyHomeContextProvider>
          <FileUploadedContextProvider>
            <Page>
              <CompanyContext.Consumer>
                {(companyContext: CompanyContextType) => {
                  if (!companyContext.initializationCompleted) {
                    return (
                      <div className="flex items-center justify-center w-full">
                        <CircularProgress />
                      </div>
                    )
                  }

                  const onboardingProgress = companyContext.onboardingProgress
                  // if (!companyContext.onboardingStatus.completed) {
                  //   return (
                  //     <Routes>
                  //       <Route path="/onboarding/firma">
                  //         <RegisterCompany />
                  //       </Route>
                  //       <Route path="/onboarding/plakat">
                  //         <NewPosterPage />
                  //       </Route>
                  //       <Route>
                  //         <OnboardingIndex />
                  //       </Route>
                  //     </Routes>
                  //   )
                  // }

                  return (
                    <WsContextProvider
                      mode="company"
                      url={process.env.REACT_APP_WAKACJE_WS_URL}
                      accessToken=""
                      companyId={companyContext.company?.id}
                    >
                      <Routes>
                        <Route path="*" element={<Organizer />} />
                        <Route
                          path="/onboarding"
                          element={
                            <RouteScreen>
                              <OnboardingIndex />
                            </RouteScreen>
                          }
                        />

                        <Route path="/student" element={<Student />} />
                        <Route
                          path="/welcome"
                          element={<NewUserWelcomeIndex />}
                        />
                        <Route
                          path="/profil"
                          element={
                            <RouteScreen>
                              <UserProfilePage />
                            </RouteScreen>
                          }
                        />
                        {/* <Route path="/login" element={<LoginPage />} />
                  <Route path="/rejestracja" element={<RegisterPage />} /> */}
                        <Route path="/sandbox">
                          <Route index={true} element={<SandboxIndex />} />
                          <Route path="testing" element={<SanboxTesting />} />
                        </Route>
                        {/* Company registration */}
                        <Route
                          path="/company/register"
                          element={<RegisterCompany />}
                        />
                        {/* <Route
                        path="/products/:productId"
                        element={<Organizer />}
                      /> */}
                        <Route path="/trips/:tripId" element={<TripPage />} />
                        <Route path="/super/*" element={<SuperAdminIndex />} />
                        <Route path="/test" element={<Test />} />
                        <>
                          <Route
                            path="/website/*"
                            element={
                              <RouteScreen>
                                <ComingSoonWidget />
                                {/* <WithRequiredOnboarding
                            onboardingProgress={onboardingProgress}
                            minStep={1}
                          >
                            <WebsiteSettingsIndex />
                          </WithRequiredOnboarding> */}
                              </RouteScreen>
                            }
                          />
                        </>
                      </Routes>
                    </WsContextProvider>
                  )
                }}
              </CompanyContext.Consumer>
            </Page>
          </FileUploadedContextProvider>
        </CompanyHomeContextProvider>
      </CompanyContextProvider>
    </BrowserRouter>
  )
}

const RedirectToLogin = () => <Navigate to="/login" />

function App() {
  const authContext = useAuthContext()

  // console.log(
  //   "authContext.currentUser",
  //   authContext.currentUser,
  //   "initi",
  //   authContext.initializationCompleted
  // )
  if (!authContext.initializationCompleted) {
    return (
      <div>
        <CircularProgress />
      </div>
    )
  }
  if (!authContext.currentUser) {
    return (
      <BrowserRouter>
        <Page>
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/rejestracja" element={<CompanyRegistrationPage />} />
            <Route path="/regulamin" element={<RegulaminIndex />} />
            <Route path="*" element={<RedirectToLogin />} />
          </Routes>
        </Page>
      </BrowserRouter>
    )
  }
  if (
    authContext.currentUser &&
    authContext.currentUser.emailVerified === false
  ) {
    return (
      <BrowserRouter>
        <Page>
          <Routes>
            <Route
              path="*"
              element={<CompanyRegistrationPage initialStep="confirmEmail" />}
            />
          </Routes>
        </Page>
      </BrowserRouter>
    )
  }
  return <AuthorizedRouter />
}

export default App
