import { useState } from "react"
import { useAuth } from "wakacje-web/src/auth/AuthContext"
import { MojeButton } from "wakacje-web/src/components/MojeButton"
import { LinkUtils } from "wakacje-web/src/shared/LinkUtils"
import { useAuthContext } from "../../contexts/auth-context"

export const CompanyRegistrationConfirmEmail = () => {
  const { currentUser } = useAuthContext()
  const { user, sendVerificationEmail } = useAuth()
  const [isSending, setIsSending] = useState(false)
  const [emailSent, setEmailSent] = useState(false)
  const handleResend = async () => {
    setIsSending(true)
    console.log(user, user)
    const href = LinkUtils.getAdminHref()
    await sendVerificationEmail(href)
    setIsSending(false)
    setEmailSent(true)
  }
  return (
    <div
      className="flex flex-col items-center pt-8 w-[90%] lg:w-[440px] mx-auto"
      id="registration-box"
    >
      <div className="text-center mb-8 text-white font-bold text-4xl mt-5">
        Sprawdź pocztę
      </div>

      <div className="text-center mb-8 text-white font-semibold text-base ">
        Na podany przez Ciebie adres mailowy{" "}
        <strong>{currentUser?.email}</strong> została wysłana wiadomość z
        linkiem aktywacyjnym. Potwierdź swój adres mailowy i dokończ
        rejestrację.
      </div>

      <div className="text-center mb-8 ">
        <img
          src="assets/registration/email-circle.svg"
          alt="email"
          className="h-[108px] w-[108px]"
        />
      </div>
      <div className="text-center mb-8 text-white font-bold text-xl ">
        Nie widzisz wiadomości?
      </div>

      <div className="text-center mb-8 text-white font-semibold text-base ">
        Wysłanie wiadomości może chwile potrwać. Sprawdź spam. Jeśli nadal masz
        problem skontaktuj się z nami poprzez{" "}
        <a
          href="mailto:kontakt@fajnyczas.pl"
          className="underline text-fc-primary"
        >
          kontakt@fajnyczas.pl
        </a>
      </div>

      <div className="text-center mb-8">
        {!emailSent && (
          <MojeButton onClick={handleResend} isSaving={isSending}>
            Wyślij email ponownie
          </MojeButton>
        )}
        {emailSent && <div>Email został wysłany</div>}
      </div>
    </div>
  )
}
