import { FormattedDate, FormattedTime } from "react-intl"
import { MojeButton } from "../../components/ui/MojeButton"
import { PaymentPopup } from "wakacje-web/src/shared/payments/PaymentPopup"
import {
  PaymentContextProvider,
  usePaymentContext
} from "wakacje-web/src/shared/payments/PaymentsContext"
import { PaymentStatusChip } from "wakacje-web/src/shared/payments/PaymentStatusChip"
import { AmountWidget } from "../../components/widgets/AmountWidget"
import { usePaymentCommand } from "../organizer/payments/usePaymentsApi"
import { PosterStatusChip } from "./PosterStatusChip"
import { PosterOrderStatus } from "./PosterUtils"
import { useSnackbar } from "notistack"
import { useAuth } from "wakacje-web/src/auth/AuthContext"

const PosterSectionPaymentInner = ({ order }: { order: any }) => {
  const [runCommand, isRunning] = usePaymentCommand()
  const paymentContext = usePaymentContext()

  const handleGoToPayment = async () => {
    console.log("handleGoToPayment")

    paymentContext.initPayment({
      type: "COMPANY_POSTER_ORDER",
      poster_order_id: order.id
    })
    // try {
    //   var res = await runCommand(command)
    //   console.log("res", res)
    // } catch (err) {
    //   console.error("Error running payment")
    // }
  }
  return (
    <div className="mb-4">
      <div className="font-bold text-xl mb-1">Płatność</div>
      <div>
        Status płatności:{" "}
        <strong>
          <PosterStatusChip order={order} />
        </strong>
      </div>
      {order.status === PosterOrderStatus.ORDERED && (
        <>
          <div className="my-2 ml-8 text-sm">
            Aby dokończyć zamawianie materiałów promocyjnych przejdź do
            płatności. Po opłaceniu usługi plik wyślemy do druku.
          </div>
          <div className="flex flex-row">
            <div>
              <MojeButton
                label="Opłać teraz"
                variant="contained"
                isSaving={isRunning}
                onClick={handleGoToPayment}
              />
            </div>
          </div>
        </>
      )}

      {order.payments && (
        <>
          <div className="font-bold mb-2">Płatności</div>
          {order.payments.map((payment: any) => (
            <div className="flex flex-row mb-1">
              <FormattedDate value={payment.created_at} />{" "}
              <FormattedTime value={payment.created_at} />
              <div className="mx-2 font-bold">
                <AmountWidget value={payment.amount} />
              </div>
              <div className="mx-2">
                <PaymentStatusChip payment={payment} />
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  )
}

export const PosterSectionPayment = ({ order }: { order: any }) => {
  const { enqueueSnackbar } = useSnackbar()
  const { auth } = useAuth()
  return (
    <PaymentContextProvider
      rootUrl={process.env.REACT_APP_WAKACJE_API_URL}
      enqueueSnackbar={enqueueSnackbar}
      accessToken={auth?.currentUser?.accessToken}
    >
      <PosterSectionPaymentInner order={order} />
      <PaymentPopup />
    </PaymentContextProvider>
  )
}
