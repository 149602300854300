import { useTranslation } from "react-i18next"
import { ProductTypes } from "wakacje-web/src/utils/ProductUtils"
import { CampFees } from "../Fees/CampFees/CampFees"

export const TripDataDialogFees = ({
  control
}: {
  productType: ProductTypes
  control: any
}) => {
  const { t } = useTranslation()
  return (
    <>
      <div className="mb-2 mt-6 font-bold text-lg">
        {t("ui.trips.fees", "Opłaty")}
      </div>
      <CampFees control={control} />
    </>
  )
}
