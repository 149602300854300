import { CircularProgress } from '@mui/material'
import { Box } from '@mui/system'
import Table from '../../../components/table/Table'
import { useProduct } from '../events/api/useProductApi'
import {
  WakacjeChip,
  WakacjeChipVariant
} from '../../../components/WakacjeChip'
import { ProductUtils } from 'wakacje-web/src/utils/ProductUtils'
import { OptionButton } from '../places/OptionButton'

const ProductTripsTable = (props: any) => {
  const { data, loading, refetch } = useProduct(props.row?.row?.id)

  const {setPopupAddDate, setPopupEditData} = props

  const columns = [
    {
      header: 'Nazwa terminu',
      cell: row => (
        <div>
          <div>{row.name}</div>
          <div>
            <WakacjeChip label={row.status} showStatus={true} />
          </div>
        </div>
      )
    },
    {
      header: 'Daty',
      cell: row => (
        <div>
          {row.start_date}-{row.end_date}
        </div>
      )
    },
    {
      header: 'Uczestnicy',
      cell: row => (
        <div>
          {row.stat_participants_count} / {row.available_spots}
        </div>
      )
    },
    {
      header: 'Cena/ 1 os.',
      cell: row => <div>{ProductUtils.formatPriceInCents(row.full_price)}</div>
    },
    {
      header: 'Suma wpłat',
      cell: row => (
        <div>{ProductUtils.formatPriceInCents(row.stat_payments_total)}</div>
      )
    },
    {
      header: '',
      cell: row => (
  
        <OptionButton
          row={row}
          handleDelete={() => {}}
          options={['edit', 'remove']}
          rowType='trip'
          setPopupEditData={setPopupEditData}
          setPopupAddDate={setPopupAddDate}
        />
      )
    }
  ]

  return (
    <Box width='100%'>
      {loading && <CircularProgress />}
      {!loading && data && (
        <Table
          rows={data.trips}
          columns={columns}
          childrenTable
          rowLinkBuilder={(row: any) => `/wydarzenia/${row.id}`}
        />
      )}
    </Box>
  )
}
export const ProductsRowDetails = ({ row,...props }: any) => {
  return <Box>{row && <ProductTripsTable row={row} {...props}/>}</Box>
}
